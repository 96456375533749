<template>
  <div class="data-view">
    <top-header />

    <el-button class="back-last-page" @click="toBack">返回</el-button>

    <div class="main-content">
      <digital-flop />

      <div class="block-left-right-content">
        <ranking-board />

        <div class="block-top-bottom-content">
          <div class="block-top-content">
            <rose-chart />

            <water-level-chart />

            <scroll-board />
          </div>

          <cards />
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import topHeader from './TopHeader'
import digitalFlop from './DigitalFlop'
import rankingBoard from './RankingBoard'
import roseChart from './RoseChart'
import waterLevelChart from './WaterLevelChart'
import scrollBoard from './ScrollBoard'
import cards from './Cards'

export default {
  name: 'DataView',
  components: {
    topHeader,
    digitalFlop,
    rankingBoard,
    roseChart,
    waterLevelChart,
    scrollBoard,
    cards
  },
  data() {
    return {}
  },
  methods: {
    toBack() {
      this.$router.back()
    }
  }
}
</script>

<style lang="less">

.data-view {
  width: 100%;
  height: 100%;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  background-image: url('./img/bg.png');
  background-size: 100% 100%;

  .main-content {
    width: 100%;
    height: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .block-left-right-content {
      width: 100%;
      height: 90%;
      display: flex;
      flex-direction: row;
      margin-top: 20px;

      .block-top-bottom-content {
        width: 0;
        flex-grow: 1;
        height: 90%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        padding-left: 20px;

        .block-top-content {
          height: 55%;
          display: flex;
          flex-direction: row;
          box-sizing: border-box;
          padding-bottom: 20px;
        }
      }

    }
  }
}

.back-last-page{
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 10;
}
</style>
